import { DatImageUtility } from "../../../services/DatImageUtility";
import { useAuth } from "../../../contexts/AuthContext";
import { LabIds, HeroType } from "../../../models";
import { BrowseCarousel } from "../BrowseCarousel";
import { useThemeContext } from "../../../contexts/ThemeContext";
import { DefaultHero } from "./DefaultHero";
import { CircleHero } from "./CircleHero";
import { useEffect, useState } from "react";

export type HeroProps = {
    heroImageHeight?: number;
};

export const Hero = (props: HeroProps) => {
    const { session, useLab } = useAuth();
    const { theme } = useThemeContext();
    const [heroType, setHeroType] = useState<HeroType>();

    useEffect(() => {
        if (useLab(LabIds.ALVERNO)) {
            setHeroType('carousel');
        } else if (useLab(LabIds.PPL)) {
            setHeroType('circle');
        } else {
            setHeroType('default');
        }
    }, [useLab]);

    const heroComponents: Record<HeroType, JSX.Element> = {
        carousel: <BrowseCarousel items={session?.appConfig?.carouselItems} />,
        circle: (
            <CircleHero
                label={session?.appConfig?.hero?.label?.text}
                imageUrl={DatImageUtility.getImageUrl(session?.lab.id, 'hero.png')}
                labelStyle={{ fontFamily: session?.appConfig?.hero?.label?.fontFamily }}
            />
        ),
        default: (
            <DefaultHero
                imageUrl={DatImageUtility.getImageUrl(session?.lab.id, 'hero.jpg')}
                imageStyle={{ height: props.heroImageHeight }}
                label={session?.appConfig.hero?.label?.text}
                labelStyle={{
                    color: theme.colors.background,
                    fontFamily: session?.appConfig.hero?.label?.fontFamily || 'Open Sans',
                    fontWeight: 'bold'
                }}
                gradientStyle={{
                    color: theme.colors.primary
                }}
            />
        )
    };

    return (
        <>
            {heroType && heroComponents[heroType]}
        </>
    );
};
