import {ImageURISource, StyleSheet, View} from "react-native";
import {Divider, MD2Colors, Surface} from "react-native-paper";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

import {HowItWorksStep} from "./HowItWorksStep";
import {HowItWorksContent} from "./HowItWorksContent";
import {Header30, Header40, SubText} from "../typography";
import {DatImageUtility} from "../../services/DatImageUtility";
import {useAuth} from "../../contexts/AuthContext";
import {DatHowItWorksContent} from "@luminate/luminate-ts-sdk";
import {HtmlView} from "../common/HtmlView";

export const HowItWorksFromConfig = (props: DatHowItWorksContent) => {
    const {session} = useAuth();

    const styles = StyleSheet.create({
        centerPage: {
            marginVertical: 40,
            alignItems: 'center',
        },
        boldText: {
            fontWeight: 'bold'
        },
        description: {
            marginTop: 10,
            lineHeight: 1.5
        }
    });

    return (
      <>
        {
          <Surface>
            <Surface>
              <View style={{ padding: 20, alignItems: 'center' }}>
                <Header40>{props.pageTitle}</Header40>
                <SubText style={{ margin: 20, lineHeight: 20 }}>{props.pageDescription}</SubText>
              </View>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly', paddingBottom: 20 }}>
                {props.steps.map((step) => (
                  <HowItWorksStep
                    icon={() => <MaterialCommunityIcons name={step.shortPart.imgPath} color={MD2Colors.white} size={24} />}
                    title={step.shortPart.title}
                    description={step.shortPart.description}
                  />
                ))}
              </View>
            </Surface>

            <Surface style={{ marginTop: 10, padding: 10 }}>
              {props.steps.filter(step => step.detailsPart !== undefined).map((step) => (
                <>
                    <HowItWorksContent
                      title={<Header30>{step.detailsPart.title}</Header30>}
                      description={<HtmlView style={styles.description}>{step.detailsPart.description}</HtmlView>}
                      reverse={props.steps.indexOf(step) % 2 !== 0}
                      image={{ uri: DatImageUtility.getJpgImageUrl(session?.lab?.id, step.detailsPart.imgPath) } as ImageURISource}
                    />

                  {props.steps.indexOf(step) < props.steps.length - 1 && <Divider />}
                </>
              ))}
            </Surface>
          </Surface>
        }
      </>
    );
}